/* eslint-disable no-param-reassign */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import LayoutPage from 'components/layout/LayoutPage'
import SecurityFooter from 'components/flex/Components/SecurityFooter'
// import { HelmetProvider } from 'react-helmet-async'

interface PageTemplateProps {
  data: {
    business: {
      path?: string
      title?: string
      seo: any
      flexContent: any
    }

    singlePrivate: {
      path?: string
      title?: string
      seo: any
      flexContent: any
    }
  }
  location?: any
  prefix?: string
  pageContext?: any
}

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: { business, singlePrivate },
  location = {},
  prefix = 'Business_Flexcontent',
  pageContext,
}) => {
  const { flexContent, seo, title } = business || singlePrivate

  if (business) {
    prefix = 'Business_Flexcontent'
  } else {
    prefix = 'SinglePrivate_Flexcontent'
  }

  return (
    // <HelmetProvider context={{}}>
    <Layout>
      <SEO seo={seo} />
      <LayoutPage
        title={title || ''}
        prefix={prefix}
        flexContent={flexContent}
        location={location}
        business={business !== null}
        pageContext={pageContext}
      />
      <SecurityFooter />
    </Layout>
    // {/* </HelmetProvider> */}
  )
}

export const pageQuery = graphql`
  query businessById($pageId: String!) {
    business: wpBusiness(id: { eq: $pageId }) {
      ...generalBusinessFragment
    }

    singlePrivate: wpSinglePrivate(id: { eq: $pageId }) {
      ...generalPrivateFragment
    }
  }
`

export default PageTemplate
