/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

// Images
import Dashes from 'img/dashes.inline.svg'
import Arrow from 'img/arrow.inline.svg'

// Components
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled, { css } from 'styled-components'

const ImageWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -2;
  bottom: 0;
  left: 0;
  height: 450px;

  & .gatsby-image-wrapper {
    width: 100%;
  }

  @media screen and (max-width: 991px) {
    position: relative;
    height: 220px;
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  height: 100%;

  @media screen and (max-width: 991px) {
    height: 220px;
  }
`

const Gradient = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: ${({ theme }) => theme.color.dark};
  mix-blend-mode: multiply;
  opacity: 0.5;
`

const ContentWrapper = styled.div`
  height: 624px;

  @media screen and (max-width: 991px) {
    height: fit-content;
  }
`

const Section = styled.section`
  height: fit-content;
  /* overflow: hidden; */
  margin-top: 150px;

  @media screen and (max-width: 991px) {
    margin-top: 50px;
  }
`

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  padding: 60px 80px;
  height: 100%;

  @media screen and (max-width: 991px) {
    padding: 20px 30px;
  }
`

const RedTitle = styled.div`
  font-size: 36px;
  line-height: 45px;
  font-weight: ${({ theme }) => theme.font.weight.bold};

  & svg {
    margin-right: 10px;
    & path {
      fill: ${({ theme }) => theme.color.primary};
    }
  }
`

const BlueTitle = styled.div`
  font-size: 36px;
  line-height: 45px;
  font-weight: ${({ theme }) => theme.font.weight.bold};

  & svg {
    margin-right: 10px;
    & path {
      fill: ${({ theme }) => theme.color.secondary};
    }
  }
`

const StyledLink = styled(Link)<{ c: string }>`
  font-size: 18px;
  line-height: 22px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  display: flex;
  align-items: center;
  transition: all 0.3s ease;

  & svg {
    margin-left: 10px;

    & path {
      transition: all 0.3s ease;
    }
  }

  &:hover {
    ${({ theme, c }) =>
      c === 'primary' &&
      css`
        color: ${theme.color.primary};
      `};

    ${({ theme, c }) =>
      c === 'secondary' &&
      css`
        color: ${theme.color.secondary};
      `};

    & svg {
      & path {
        ${({ theme, c }) =>
          c === 'primary' &&
          css`
            fill: ${theme.color.primary};
          `};

        ${({ theme, c }) =>
          c === 'secondary' &&
          css`
            fill: ${theme.color.secondary};
          `};
      }
    }
  }

  @media screen and (max-width: 991px) {
    line-height: 16px;
    font-size: 18px;
  }
`

const ContentRow = styled.div`
  position: absolute;
  width: 100%;
  top: -90px;

  @media screen and (max-width: 991px) {
    position: relative;
    width: unset;
    top: unset;
    margin-bottom: -30px;
  }
`

interface SecurityFooterProps {}

const SecurityFooter: React.FC<SecurityFooterProps> = () => {
  const query = useStaticQuery<GatsbyTypes.securityFooterQuery>(graphql`
    query securityFooter {
      wpComponent(databaseId: { eq: 578 }) {
        title
        securityFooter {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 2000)
              }
            }
          }
        }
      }

      business: allWpBusiness {
        nodes {
          title
          uri
        }
      }

      private: allWpSinglePrivate {
        nodes {
          title
          uri
        }
      }
    }
  `)

  return (
    <Section className="position-relative">
      <ContentWrapper className="container position-relative">
        <ContentRow className="row justify-content-between">
          <div className="col-lg-8 mb-lg-0 mb-4">
            <Block>
              <RedTitle className="d-flex align-items-center">
                <Dashes />
                Zakelijk
              </RedTitle>
              <div className="row pt-4">
                {query.business.nodes.map((b, index) => (
                  <div className="col-lg-6">
                    <div className="my-2 py-2">
                      <StyledLink c="primary" to={b.uri || '/'} key={index}>
                        {b.title}
                        <Arrow />
                      </StyledLink>
                    </div>
                  </div>
                ))}
              </div>
            </Block>
          </div>
          <div className="col-lg-4">
            <Block>
              <BlueTitle className="d-flex align-items-center">
                <Dashes />
                Particulier
              </BlueTitle>
              <div className="row pt-4">
                {query.private.nodes.map((b, index) => (
                  <div className="col-lg-12">
                    <div className="my-2 py-2">
                      <StyledLink c="secondary" to={b.uri || '/'} key={index}>
                        {b.title}
                        <Arrow />
                      </StyledLink>
                    </div>
                  </div>
                ))}
              </div>
            </Block>
          </div>
        </ContentRow>
      </ContentWrapper>
      <ImageWrapper>
        <Gradient />
        <StyledPlaatjie
          image={query.wpComponent?.securityFooter?.image}
          alt=""
        />
      </ImageWrapper>
    </Section>
  )
}

export default SecurityFooter
